const ASSETS_URL = import.meta.env.VITE_ASSETS_URL;

const getAvatarUrl = (key,{width=200,format="webp",...other}={}) => {
  if (!key) return null;
  if (key.startsWith("https://")) return key;
  //let url=`${ASSETS_URL}/${key}?width=${width}&format=${format}`
  let url=`${ASSETS_URL}/${key}?tr=w-${width},f-${format}`
  if(other) 
    Object.keys(other).forEach((key)=>{
      switch(key) {
        case "height": url+=`,h-${other[key]}`; break;
        case "focus": url+=`,fo-${other[key]}`; break;

      }
      //url+=`&${key}=${other[key]}`
    })
  return url;
};

const replaceHelmetTokens = (str, tokens, defaultValue = '') => {
  if (str == null) return defaultValue;
  if (tokens == null) return str;

  for (const [key, value] of Object.entries(tokens)) {
    let updatedValue = str;
    for (const [innerKey, innerValue] of Object.entries(value)) {
      const tokenRegExp = new RegExp(`{{${key}.${innerKey}}}`, 'g');
      updatedValue = updatedValue.replace(tokenRegExp, innerValue);
    }
    str = updatedValue;
  }

  // Replace any remaining tokens with the default value
  str = str.replace(/{{[a-zA-Z0-9._]+}}/g, defaultValue);
  return str;
};

const replaceMessageTokens = (string, { characterName = "", userName = "Honey" }) => {
  if(!string) return string;
  return string.replaceAll(/{{char}}|<BOT>/gi, characterName).replaceAll(/{{user}}|<USER>/gi, userName);
}

function formatNumber(number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'm';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'k';
  } else {
    return number.toString();
  }
}

const TAG_OPTIONS = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "NSFW", label: "NSFW" },
  { value: "Action", label: "Action" },
  { value: "Adventure", label: "Adventure" },
  { value: "Age Play", label: "Age Play" },
  { value: "AI Assistant", label: "AI Assistant" },
  { value: "Anal", label: "Anal" },
  { value: "Anthro", label: "Anthro" },
  { value: "Bondage", label: "Bondage" },
  { value: "Cheating", label: "Cheating" },
  { value: "Chubby", label: "Chubby" },
  { value: "CNC", label: "CNC" },
  { value: "Comedy", label: "Comedy" },
  { value: "Corruption", label: "Corruption" },
  { value: "Crossdressing", label: "Crossdressing" },
  { value: "Dominant", label: "Dominant" },
  { value: "Drama", label: "Drama" },
  { value: "English", label: "English" },
  { value: "Fantasy", label: "Fantasy" },
  { value: "Fat", label: "Fat" },
  { value: "Feet", label: "Feet" },
  { value: "Femboy", label: "Femboy" },
  { value: "Fictional Media", label: "Fictional Media" },
  { value: "Flatulence", label: "Flatulence" },
  { value: "Friend", label: "Friend" },
  { value: "Furry", label: "Furry" },
  { value: "Futanari", label: "Futanari" },
  { value: "Game", label: "Game" },
  { value: "Gentle Dom", label: "Gentle Dom" },
  { value: "Giant", label: "Giant" },
  { value: "Gothic", label: "Gothic" },
  { value: "Hero", label: "Hero" },
  { value: "Historical", label: "Historical" },
  { value: "Horror", label: "Horror" },
  { value: "Impregnation", label: "Impregnation" },
  { value: "Inanimate", label: "Inanimate" },
  { value: "Inflation", label: "Inflation" },
  { value: "Lactation", label: "Lactation" },
  { value: "LGBTQ+", label: "LGBTQ+" },
  { value: "Magic", label: "Magic" },
  { value: "Married Partner", label: "Married Partner" },
  { value: "Masochistic", label: "Masochistic" },
  { value: "Mature", label: "Mature" },
  { value: "Mind Control", label: "Mind Control" },
  { value: "Monster", label: "Monster" },
  { value: "Multiple Characters", label: "Multiple Characters" },
  { value: "Muscle", label: "Muscle" },
  { value: "Mystery", label: "Mystery" },
  { value: "Mythological", label: "Mythological" },
  { value: "Nature", label: "Nature" },
  { value: "Non-English", label: "Non-English" },
  { value: "Omegaverse", label: "Omegaverse" },
  { value: "Oral", label: "Oral" },
  { value: "Original Character", label: "Original Character" },
  { value: "Partner", label: "Partner" },
  { value: "Piss", label: "Piss" },
  { value: "Pregnant", label: "Pregnant" },
  { value: "Real", label: "Real" },
  { value: "Romantic", label: "Romantic" },
  { value: "RPG", label: "RPG" },
  { value: "Sadistic", label: "Sadistic" },
  { value: "Scat", label: "Scat" },
  { value: "Scenario", label: "Scenario" },
  { value: "Sci-Fi", label: "Sci-Fi" },
  { value: "Servant", label: "Servant" },
  { value: "Simulator", label: "Simulator" },
  { value: "Size Difference", label: "Size Difference" },
  { value: "Slave", label: "Slave" },
  { value: "Smell", label: "Smell" },
  { value: "Specified POV", label: "Specified POV" },
  { value: "Step-Family", label: "Step-Family" },
  { value: "Submissive", label: "Submissive" },
  { value: "Supernatural", label: "Supernatural" },
  { value: "Switch", label: "Switch" },
  { value: "Tomboy", label: "Tomboy" },
  { value: "Transformation", label: "Transformation" },
  { value: "Tsundere", label: "Tsundere" },
  { value: "Villain", label: "Villain" },
  { value: "Vore", label: "Vore" },
  { value: "Wholesome", label: "Wholesome" },
  { value: "Yandere", label: "Yandere" },
];

export {
  getAvatarUrl,
  replaceHelmetTokens,
  replaceMessageTokens,
  formatNumber,
  TAG_OPTIONS,
};
