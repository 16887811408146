
import {useState} from "react";
import PropTypes from "prop-types";
import { Text, Col, Tooltip, styled, Loading, Checkbox } from "@nextui-org/react";
//import { useNavigate } from "react-router-dom";
import useApi from "@/components/useApi";
import { RiGitRepositoryPrivateLine as PrivateIcon } from "react-icons/ri"
import { GiChiliPepper as NotUnderageIcon } from "react-icons/gi";
import { MdDelete as DeleteIcon } from "react-icons/md";

const IconButton = styled('button', {
    dflex: 'center',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0',

    paddingLeft: '2px',
    margin: '0',
    bg: 'transparent',
    transition: '$default',
    '&:hover': {
        opacity: '0.8'
    },
    '&:active': {
        opacity: '0.6'
    }
});


export default function ModerationActions({ character_id, onComplete, }) {
    const [emailEnabled, setEmailEnabled] = useState(true);
    const { jsonApiService, callApi: callActionApi, isLoading } = useApi();

    const makePrivate = async (id) => {
        if(emailEnabled) await callActionApi(jsonApiService.createResource, 'admin/reviews/email',{ "character_id": id, "action": "private"});    
        await callActionApi(jsonApiService.updateResource, 'admin/characters', id, { "visibility": 'private' });
        onComplete({ action: 'made_private', id: id })
    };

    const makeNSFW = async (id) => {
        await callActionApi(jsonApiService.updateResource, 'admin/characters', id, { "is_nsfw": true });
        onComplete({ action: 'set_nsfw', id: id })
    };

    const resetUnderage = async (id) => {
        await callActionApi(jsonApiService.updateResource, 'admin/characters', id, { "is_underage": false });
        onComplete({ action: 'reset_underage', id: id })
    };

    const deleteCharacter = async (id) => {
        if(emailEnabled) await callActionApi(jsonApiService.createResource, 'admin/reviews/email',{ "character_id": id, "action": "delete"});            
        await callActionApi(jsonApiService.deleteResource, 'admin/characters', id)
        onComplete({ action: 'deleted', id: id })
    };
    if (isLoading) return <Loading size="xs" />

    return (
        <>
            <Col css={{ d: "flex" }}>
                <Tooltip content="Not Underage">
                    <IconButton onClick={() => resetUnderage(character_id)}>
                        <NotUnderageIcon size={20} />
                    </IconButton>
                </Tooltip>
            </Col>
            <Col css={{ d: "flex" }}>
                <Tooltip content="NSFW">
                    <IconButton onClick={() => makeNSFW(character_id)}>
                        <Text size={10}>NSFW</Text>
                    </IconButton>
                </Tooltip>
            </Col>
            <Col css={{ d: "flex" }}>
                <Tooltip content="Make Private">
                    <IconButton onClick={() => makePrivate(character_id)}>
                        <PrivateIcon size={20} />
                    </IconButton>
                </Tooltip>
            </Col>
            <Col css={{ d: "flex" }}>

                <Tooltip
                    content="Delete character"
                    color="error"
                    onClick={() => deleteCharacter(character_id)}
                >
                    <IconButton>
                        <DeleteIcon size={20} fill="#FF0080" />
                    </IconButton>
                </Tooltip>
                <Tooltip content="Email Enabled">
                <Checkbox alt="test" isSelected={emailEnabled} color="neutral" size="xs" onChange={setEmailEnabled}></Checkbox>
                </Tooltip>
            </Col>
            
        </>
    );
}

ModerationActions.propTypes = {
    character_id: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
};

