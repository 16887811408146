import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  Button,
  Grid,
  Image,
} from "@nextui-org/react";
import AvatarModal from "@/components/CharacterForm/AvatarModal";
import { getAvatarUrl } from "@/libs/utils";
import useAvatarUpload from "@/components/CharacterForm/useAvatarUpload";

const AvatarCreate = ({ characterAvatar, setCharacterAvatar }) => {
  const [isAvatarModalVisible, setIsAvatarModalVisible] = useState(false);

  const { onImageUpload, localFilename, avatarFileKey } = useAvatarUpload();

  useEffect(() => {
    if (avatarFileKey) {
      setCharacterAvatar(avatarFileKey);
    }
  }, [avatarFileKey, setCharacterAvatar]);


  const onCloseHandler = () => {
    setIsAvatarModalVisible(false);
  };

  const onSaveHandler = (key) => {
    console.log("saving key", key);
    setCharacterAvatar(key);
    setIsAvatarModalVisible(false);
  };


  return (
    <>
      <Grid.Container gap={2} alignItems='flex-end'>
        {characterAvatar && <Grid>
          <Image src={getAvatarUrl(characterAvatar)} width={256} height={256} />
        </Grid>}
        <Grid css={{p:0}} >
          <Grid.Container gap={0} alignItems="center" justify="center">
            <Grid xs={12} sm={5}>
              <Button size="sm" onClick={() => setIsAvatarModalVisible(true)} css={{'@xsMax':{width:"100%"}}}>Generate Avatar</Button>
            </Grid>
            <Grid xs={0} sm={1}>or</Grid>
            <Grid xs={12} sm={6} >
              <Button.Group size="sm" >
                <Button onPress={onImageUpload} css={{'@xsMax':{width:"150px"}}}>
                  Choose File
                </Button>
                <Button disabled={true} css={{'@xsMax':{width:"150px"}}}>
                  {localFilename}
                </Button>
              </Button.Group>
            </Grid>
          </Grid.Container>
        </Grid>
      </Grid.Container>
      <AvatarModal
        currentAvatarUrl={getAvatarUrl(characterAvatar)}
        isVisible={isAvatarModalVisible}
        onClose={onCloseHandler}
        onSave={onSaveHandler}
      />
    </>
  );
};

AvatarCreate.propTypes = {
  characterAvatar: PropTypes.string,
  setCharacterAvatar: PropTypes.func,
};
export default AvatarCreate;