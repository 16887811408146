import { PropTypes } from "prop-types";
import { Radio , Text,Spacer} from "@nextui-org/react";
import { useField } from "@formiz/core";

const RadioGroupField = ({ title, description, options = [],  ...props }) => {
    const { value, setValue,errorMessage, otherProps } = useField(props);

    return (
        <>
            <Text b css={{ ml: "2px" }}>
                {title}
            </Text>
            <Text css={{ ml: "12px" }} small>
                {description}
            </Text>
            <Spacer y={0.1} />

            <Radio.Group {...otherProps} value={value} onChange={(value)=>setValue(value)}>
                {options.map((option) => (
                    <Radio value={option.value} aria-labelledby={option.value} key={option.value} isDisabled={option.isDisabled}>{option.label}</Radio>))}
            </Radio.Group>
            <Text color="error">{errorMessage}</Text>
            <Spacer y={0.5} />
        </>
    );
};

RadioGroupField.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            text: PropTypes.string,
        })
    ),

};
export default RadioGroupField;
