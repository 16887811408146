const TAG_CSS_OBJ = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    padding: "6px",
    background: "#000000",
    width: "40vw",
    outline: "2px solid  #16181a",
    border: state.isFocused ? "2px solid #16181a" : "2px solid #16181a",
    "&:hover": {
      ringOffsetWidth: "2px",
      ringOffsetColor: "#16181a",
      ringColor: "#16181a",
    },

    "@media (max-width: 768px)": {
      width: "90vw",
    },
  }),
  option: (provided) => ({
    ...provided,
    background: "#000000",
    color: "white",

    "&:hover": {
      background: "#16181a",
      color: "white",
    },
  }),

  multiValue: (provided) => ({
    ...provided,
    background: "#16181a",
    color: "white",
    padding: "1px 2px",
    borderRadius: "28px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "white",
    "&:hover": {
      background: "#16181a",
      color: "white",
      cursor: "pointer",
    },
  }),

  menu: (provided) => ({
    ...provided,
    background: "#000000",
    scrollBehavior: "smooth",
    scrollbarWidth: "none",
    padding: "0px",
    borderRadius: "8px",
    outline: "4px solid  #16181a",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "12px",
    "::-webkit-scrollbar": {
      width: "0px",
      height: "0px",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
};

export default TAG_CSS_OBJ;
