import { useState } from "react";
import useApi from "@/components/useApi";
import pngCharacterCard from "@/libs/pngCharacterCard";




const useAvatarUpload = () => {
  const [localFilename, setLocalFilename] = useState('No File Chosen');
  const [importedJsonData, setImportedJsonData] = useState(null);
  const [avatarFileKey, setAvatarFileKey] = useState(null);
  const { jsonApiService, callApi } = useApi();

  // Function to strip URLs from a string
  function stripUrls(str) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return str.replace(urlRegex, '');
  }

  const onCharacterCardUpload = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json, image/png';
    input.onchange = (async (event) => {
      const file = event.target.files[0];
      let data;
      if (file.type === 'application/json') {
        data = JSON.parse(await file.text());
      } else if (file.type === 'image/png') {
        const image = await file.arrayBuffer()
        const text = pngCharacterCard.Parse(image);
        try {
          data = JSON.parse(text);
          // Iterate over the data object and strip URLs from string values
          Object.keys(data).forEach(key => {
            if (typeof data[key] === 'string') {
              data[key] = stripUrls(data[key]);
            } else if (typeof data[key] === 'object') {
              // Recursively strip URLs from nested objects or arrays
              data[key] = stripUrls(JSON.stringify(data[key]));
            }
          });
          saveUploadedFile(arrayBufferToBase64(image), 'image/png')
        } catch (ex) {
          throw new Error('Error with import', {
            cause: ex
          });
        }
      }
      setImportedJsonData(data);

    });
    input.click();
  }

  const onImageUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = ((event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        const contentType = dataURL.split(';')[0].split(':')[1]; // Extract content type
        const base64Data = dataURL.split(',')[1]; // Extract base64 data from data URL
        saveUploadedFile(base64Data, contentType)
        setLocalFilename(file.name);
      };
      reader.readAsDataURL(file);
    });
    input.click();
  };

  const base64ToBlob = (base64, type) => {
    const binary = atob(base64);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  }

  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  const saveUploadedFile = async (base64Data, contentType) => {
    await callApi(jsonApiService.saveImage, {}).then(async (result) => {
      await fetch(result.signed_url, {
        method: 'PUT',
        body: base64ToBlob(base64Data, contentType),
        headers: {
          'Content-Type': contentType,
        },
      });
      setAvatarFileKey(result.key);
    }
    ).catch((error) => {
      console.log(error);
    }
    );
  }

  return { onImageUpload, localFilename, avatarFileKey, onCharacterCardUpload, importedJsonData };
}
export default useAvatarUpload;