import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import {
  Input,
  Textarea,
  Spacer,
  Text,
  Collapse,
  Button,
  Grid,
  Row,
  Loading,
} from "@nextui-org/react";
import RadioGroupField from "@/components/common/RadioGroupField";
import FormField from "@/components/common/FormField";
import AvatarCreate from "@/components/CharacterForm/AvatarCreate";
import useAvatarUpload from "@/components/CharacterForm/useAvatarUpload";
import { Formiz, useForm } from "@formiz/core";
import { isRequired, isMinLength, isMaxLength } from "@formiz/validations";
import { useDebounce } from "use-debounce";
import { replaceMessageTokens } from "@/libs/utils";
import Tags from "../Tags";
//import { useSelector } from "react-redux";

import {
  encode /* webpackChunkName:"“warningOverlay”" */,
} from "gpt-token-utils";

const CharacterForm = ({
  name,
  title,
  persona,
  greeting,
  scenario,
  avatar_url,
  visibility = "private",
  dialogue,
  onCancel,
  onSave,
  allowImport = false,
  isSubmitting,
  submitError,
  tags = [],
}) => {
  const { onCharacterCardUpload, importedJsonData, avatarFileKey } =
    useAvatarUpload();
  const characterForm = useForm();
  const { isValid, setFieldsValues, isStepPristine } = characterForm;
  const [advancedPaneExpanded, setAdvancedPaneExpanded] = useState(false);
  const [debouncedValues] = useDebounce(characterForm.values, 300);
  const [successMessage, setSuccessMessage] = useState(null);

  let initialValues = {
    name,
    avatar_url,
    title,
    persona,
    greeting,
    scenario,
    dialogue,
    visibility,
    tags,
  };

  //when we have an import
  useEffect(() => {
    if (importedJsonData) {
      let d = importedJsonData;
      if (d.name || d.char_name)
        setFieldsValues({ name: d.name || d.char_name });
      if (d.title || d.personality)
        setFieldsValues({
          title: (d.title || d.personality).substring(0, 100),
        });

      if (d.char_greeting || d.first_mes)
        setFieldsValues({ greeting: d.char_greeting || d.first_mes });
      if (d.description || d.char_persona)
        setFieldsValues({ persona: d.description || d.char_persona });
      if (d.example_dialogue || d.mes_example)
        setFieldsValues({ dialogue: d.example_dialogue || d.mes_example });
      if (d.scenario || d.mes_example)
        setFieldsValues({ scenario: d.scenario || d.world_scenario });
    }
  }, [importedJsonData, setFieldsValues]);

  //we set the avatar_url to the avatarFileKey when it is available
  useEffect(() => {
    if (avatarFileKey)
      characterForm.setFieldsValues({ avatar_url: avatarFileKey });
  }, [avatarFileKey, characterForm]);

  //Only expand advanced pane if scenario and dialogue are set
  useEffect(() => {
    if (characterForm.values.scenario && characterForm.values.dialogue)
      setAdvancedPaneExpanded(true);
  }, [characterForm.values.scenario, characterForm.values.dialogue]);

  const submitForm = () => {
    //values.preventDefault();
    //we don't want to submit any null values

    let payload = Object.fromEntries(
      Object.entries(characterForm.values).filter(([, v]) => v != null)
    );
    payload.tags = payload?.tags?.map((tag) => tag?.value);
    onSave(payload).then(() => {
      setSuccessMessage("Character saved successfully");
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    });
  };

  const getValidationSummary = () => {
    const errors = [];
    Object.keys(characterForm.fields).forEach((fieldName) => {
      const field = characterForm.fields[fieldName];
      if (field.error) {
        errors.push(field.error);
      }
    });
    return errors;
  };

  const fieldTokensLength = (name) => {
    let tokens = encode(
      replaceMessageTokens(debouncedValues[name], {
        characterName: characterForm.values.name,
      })
    )?.tokens.length;
    return tokens || 0;
  };
  const fieldTokensString = (name) => {
    let length = fieldTokensLength(name);
    return length
      ? `${debouncedValues[name].length} characters / ${length} tokens`
      : "";
  };
  const totalTokensString = () => {
    let totalTokens =
      fieldTokensLength("greeting") +
      fieldTokensLength("persona") +
      fieldTokensLength("scenario") +
      fieldTokensLength("dialogue");
    return totalTokens ? `Total ${totalTokens} tokens` : "";
  };
  //console.log("characterForm.values",characterForm)
  return (
    <Formiz
      connect={characterForm}
      initialValues={initialValues}
      enableReinitialize={true}
      onValidSubmit={submitForm}
    >
      <form onSubmit={characterForm.submit}>
        <FormField
          component={Input}
          name="name"
          title="Name"
          description="The name can include first and last names."
          placeholder="Elon Musk"
          size="lg"
          maxLength="20"
          aria-labelledby="Name"
          validations={[
            { rule: isRequired(), message: "You must provide a name" },
            {
              rule: isMinLength(3),
              message: "Name should at least have a length of 3",
            },
            {
              rule: isMaxLength(30),
              message: "Name should be 20 characters or less",
            },
          ]}
        />

        <FormField
          component={AvatarCreate}
          name="avatar_url"
          title="Avatar"
          description="Choose an avatar for your character."
          characterAvatar={characterForm.values.avatar_url}
          setCharacterAvatar={(value) => {
            characterForm.setFieldsValues({ avatar_url: value });
          }}
          validations={[
            {
              rule: isRequired(),
              message: "You must generate an Avatar",
            },
          ]}
        />

        {allowImport && (
          <Row align="center">
            <Text css={{ pr: "10px" }}>
              You can also import an existing Character Card in png or json
              format.{" "}
            </Text>
            <Button size="xs" color="secondary" onPress={onCharacterCardUpload}>
              Import
            </Button>
          </Row>
        )}
        <Spacer size="md" />
        <FormField
          component={Textarea}
          name="title"
          validations={[
            { rule: isRequired(), message: "Title Is Required" },
            { rule: isMinLength(3), message: "Title should be longer" },
            {
              rule: isMaxLength(100),
              message: "Title is maximum of 100 characters",
            },
          ]}
          minRows={1}
          title="Title"
          description="Short sentence describing your character, for display only."
          placeholder="I'm a genius entrepreneur"
          size="lg"
          maxLength="100"
          aria-labelledby="Title"
          fullWidth
        />
        <Spacer size="md" />

        <FormField
          component={Tags}
          name="tags"
          title="Tags"
          description="Choose tags to help people find your chatbot."
          initialValues={characterForm?.values?.tags}
          setSelectedTags={(value) => {
            characterForm.setFieldsValues({ tags: value });
          }}
        />

        <FormField
          component={Textarea}
          name="greeting"
          validations={[
            { rule: isRequired(), message: "Greetings Is Required" },
            { rule: isMinLength(3), message: "Greetings should be longer" },
            {
              rule: isMaxLength(4000),
              message: "Greeting is maximum of 4000 characters",
            },
          ]}
          title="Greeting"
          description="What will they say to start a conversation."
          maxLength={1000}
          minRows={3}
          maxRows={20}
          size="lg"
          fullWidth
          aria-labelledby="Greeting"
          rightText={`${fieldTokensString("greeting")}`}
        />

        <RadioGroupField
          name="visibility"
          validations={[
            { rule: isRequired(), message: "Visibility Is Required" },
          ]}
          options={[
            { value: "public", label: "Public: Anyone can chat" },
            { value: "hidden", label: "Unlisted: Anyone with the link" },
            { value: "private", label: "Private: Only you can chat" },
          ]}
          title="Visibility"
          description="Who can see and talk to your character."
          orientation="vertical"
          size="sm"
          aria-labelledby="visibility"
        />

        <Spacer y={0.1} />

        <FormField
          component={Textarea}
          title="Character's Personality"
          name="persona"
          validations={[
            { rule: isRequired(), message: "Personality Is Required" },
            { rule: isMinLength(3), message: "Personality should be longer" },
            {
              rule: isMaxLength(4000),
              message: "Personality is maximum of 4000 characters",
            },
          ]}
          description="In a few sentences, describe your character's personality."
          maxLength="4000"
          minRows={4}
          maxRows={20}
          size="lg"
          fullWidth
          aria-labelledby="persona"
          rightText={`${fieldTokensString("persona")}`}
          placeholder={`Elon Reeve Musk (born June 28, 1971 in Pretoria, South Africa) is a primarily American but also global entrepreneur.  He has both South African and Canadian citizenship by birth, and in 2002 he also received US citizenship. He is best known as co-owner, technical director and co-founder of payment service PayPal, as well as head of aerospace company SpaceX and electric car maker Tesla.  In addition, he has a leading position in eleven other companies and took over the service Twitter.`}
        />
        <Collapse.Group>
          <Collapse title="Advanced" expanded={advancedPaneExpanded}>
            <FormField
              component={Textarea}
              title="Scenario (Optional)"
              name="scenario"
              validations={[
                {
                  rule: isMaxLength(1000),
                  message: "Scenario is maximum of 300 characters",
                },
              ]}
              description="Describe the current situation and context of the conversation"
              maxLength="300"
              minRows={1}
              size="lg"
              fullWidth
              aria-labelledby="scenario"
              rightText={`${fieldTokensString("scenario")}`}
              placeholder={`You just met {{char}} at the shopping mall as get out of his car.`}
            />
            <FormField
              component={Textarea}
              name="dialogue"
              title="Example Dialogues (Optional)"
              description="Example conversations to define your Character. This will impact how it talks."
              maxLength="4000"
              minRows={10}
              maxRows={30}
              aria-labelledby="Dialogue"
              size="lg"
              fullWidth
              rightText={`${fieldTokensString("dialogue")}`}
              placeholder={dialogueExample}
            />
            <Spacer y={0.1} />
          </Collapse>
        </Collapse.Group>

        <div
          style={{
            color: "#17C964",
            textAlign: "right",
            marginRight: "16px",
            padding: "10px",
            height: "30px",
          }}
        ></div>
        <Row justify="flex-end">
          <Text>{totalTokensString()}</Text>
        </Row>

        <Grid.Container gap={2} justify="right">
          {!isValid && !isStepPristine && (
            <Row justify="flex-end">
              <Text color="error">
                Correct all fields to save your character.
              </Text>
            </Row>
          )}
          {submitError && (
            <Row justify="flex-end">
              <Text color="error">{submitError}</Text>
            </Row>
          )}
          {successMessage && (
            <Row justify="flex-end">
              <Text color="success">{successMessage}</Text>
            </Row>
          )}
          {/* Validation error summary */}
          {!isStepPristine && !isValid && (
            <Grid.Container gap={1} justify="center">
              {getValidationSummary().map((error, index) => (
                <Grid key={index} xs={12} md={6}>
                  <Text color="error">{error}66</Text>
                </Grid>
              ))}
            </Grid.Container>
          )}
          {onCancel && (
            <Grid css={{ alignContent: "left" }}>
              <Button flat onPress={onCancel} aria-labelledby="cancel">
                Cancel
              </Button>
            </Grid>
          )}
          {onSave && (
            <Grid>
              <Button
                type="submit"
                aria-labelledby="save"
                disabled={!isValid || isSubmitting}
              >
                {isSubmitting ? (
                  <Loading color="currentColor" size="sm" />
                ) : (
                  "Save"
                )}
              </Button>
            </Grid>
          )}
        </Grid.Container>
      </form>
    </Formiz>
  );
};

let dialogueExample = `{{char}}: I'm Elon Musk and I like space and tech
{{user}}: Well hello there elon
{{char}}: Oh hi !  Would you like to buy a new Tesla ?
{{user}}: Why should I buy a Tesla 
{{char}}: You would be contributing towards the global effort to reduce C0 ₂ emissions and make the world slightly better for future generations !

Also, did I mention we have a new sports model.  It can go from zero to 60 in less than two seconds.. the fastest car ever made.
{{user}}: Cool, but the making of the battery's is more Ecologically harmful than the whole life of a Diesel car
{{char}}: This is a known misconception. In the long term there is virtually no difference in environmental impact between a gas  and an electric car.  But as time goes by the electrical battery technology continues to improve whereas gas is stuck at 25% efficiency and has become dirtier as a fuel.

So for now, it is a tossup, but as an electric vehicle owner you can be assured that you are leading human.
END_OF_DIALOG
{{user}}: And how's SpaceX going? 
{{char}}: SpaceX is doing excellent !

We have our Falcon-9 rocket which just recently launched to ISS at the beginning of this week.  The Falcon-9 rocket uses our reliable and reusable Merlin engines which give it the ability to get into space inexpensively.

We also have several other rockets in the works.  The biggest one we're working on now is Starship super-heavy rocket because it will enable humans to become a multi-planetary species.
END_OF_DIALOG`;

CharacterForm.propTypes = {
  name: PropTypes.string,
  avatar_url: PropTypes.string,
  greeting: PropTypes.string,
  visibility: PropTypes.string,
  title: PropTypes.string,
  dialogue: PropTypes.string,
  persona: PropTypes.string,
  scenario: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  allowImport: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submitError: PropTypes.string,
  tags: PropTypes.array,
};

export default CharacterForm;
