import PropTypes from "prop-types";
import { Modal } from "@nextui-org/react";
import EditCharacterPage from "../../routes/EditCharacterPage";

export default function EditModal({ visible, closeHandler, editId }) {

  return (
    <div>
      <Modal
        width="75%"
        closeButton
        //preventClose
        aria-labelledby="Edit Character"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Body>
          <EditCharacterPage characterId={editId} closeHandler={closeHandler} />
        </Modal.Body>
      </Modal>
    </div>
  );

}

EditModal.propTypes = {
  visible: PropTypes.bool,
  closeHandler: PropTypes.func,
  editId: PropTypes.string,
};