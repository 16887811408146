import { useState, useCallback } from "react";
import jsonApiService from "@/services/jsonApiService";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useSelector } from "react-redux";

function useApi() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { getToken } = useKindeAuth() || {};
  const guestUserId = useSelector((state) => state.user.guestUserId);


  const callApi = useCallback(
    async (apiFunc, ...args) => {
      setIsLoading(true);
      setError(null);

      await getToken().then((userToken) => {
        console.log("userToken", userToken)
        jsonApiService.setUserToken(userToken);
        if (guestUserId) {
          jsonApiService.setGuestUserId(guestUserId);
        }
      });
      try {
        const boundApiFunc = apiFunc.bind(jsonApiService);
        const result = await boundApiFunc(...args);
        setData(result);
        setIsLoading(false);
        return result;
      } catch (error) {
        setIsLoading(false);
        if (error?.response?.data?.error) {
          setError(error?.response?.data?.error);
          throw new Error(error?.response?.data?.error);
        } else {
          setError(error.message);
          throw new Error(error?.message);
        }
      }
    }, [getToken, guestUserId]);

  return { data, jsonApiService, callApi, isLoading, error };
}

export default useApi;
