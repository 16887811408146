import { useEffect, useMemo } from "react";
import { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { TAG_OPTIONS } from "../libs/utils";
import TAG_CSS_OBJ from "./TagsCss"


const Tags = ({ initialValue, setSelectedTags }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = useMemo(
    () => TAG_CSS_OBJ,
    []
  );

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    setSelectedTags(selectedOption);
  }, [selectedOption]);

  return (
    <div className="tags-container">
      <Select
        defaultValue={initialValue}
        onChange={handleChange}
        options={TAG_OPTIONS}
        isMulti
        styles={customStyles}
        className="tags-select"
        placeholder="Select tags"
        closeMenuOnSelect={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

Tags.propTypes = {
  setSelectedTags: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
};
export default Tags;