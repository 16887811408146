import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  Modal,
  Button,
  Text,
  Textarea,
  Image,
  Loading,
  Container,
  Grid,
  Row,
} from "@nextui-org/react";
import useApi from "@/components/useApi";
import { getAvatarUrl } from "@/libs/utils";

const MAX_DAILY_GENERATION = 30;
const AvatarModal = ({ isVisible, currentAvatarUrl, onClose, onSave }) => {
  const { jsonApiService, callApi, isLoading, error } = useApi();
  const [isError, setIsError] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(currentAvatarUrl);
  const [imageData, setImageData] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [avatarCount, setAvatarCount] = useState(MAX_DAILY_GENERATION);
  const [avatarImages, setAvatarImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const base64ToBlob = (base64, type) => {
    const binary = atob(base64);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  };

  const handleSave = async () => {
    const newImageData = avatarImages[currentImageIndex].substring(
      `data:${imageData.type};base64,`.length
    );
    let bodyData = avatarImages.length > 1 ? newImageData : imageData.base64;
    await callApi(jsonApiService.saveImage, { prompt })
      .then(async (result) => {
        await fetch(result.signed_url, {
          method: "PUT",
          body: base64ToBlob(bodyData, imageData.type),
          headers: {
            "Content-Type": imageData.type,
          },
        });
        onSave(result.key);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onGenerate = async () => {
    setIsError(false);

    // update day limit @abhay
    if (avatarCount <= 0) {
      alert("You have exceeded the daily limit for avatar generation.");
      return;
    }

    const storedData = localStorage.getItem("avatarData");
    if (storedData) {
      const { date } = JSON.parse(storedData);
      const currentDate = new Date().toISOString().split("T")[0];
      if (date !== currentDate) {
        updateAvatarCount(MAX_DAILY_GENERATION);
      }
    }
    // update day limit @abhay

    await callApi(jsonApiService.generateImage, { prompt }).then((result) => {
      if (!error && result.base64) {
        setAvatarUrl(`data:${result.type};base64,${result.base64}`);
        setAvatarImages([
          ...avatarImages,
          `data:${result.type};base64,${result.base64}`,
        ]);
        setCurrentImageIndex(avatarImages.length);
        setImageData({ ...result, prompt });
        updateAvatarCount(avatarCount);
      }
    });
  };

  useEffect(() => {
    return () => {
      if (avatarUrl) {
        URL.revokeObjectURL(avatarUrl);
      }
    };
  }, [avatarUrl]);

  const updateAvatarCount = (count) => {
    const currentDate = new Date().toISOString().split("T")[0];
    const data = {
      count,
      date: currentDate,
    };

    setAvatarCount(count);
    localStorage.setItem("avatarData", JSON.stringify(data));
  };

  useEffect(() => {
    const storedData = localStorage.getItem("avatarData");
    if (storedData) {
      const { count, date } = JSON.parse(storedData);
      const currentDate = new Date().toISOString().split("T")[0];

      if (date === currentDate) {
        setAvatarCount(
          count < MAX_DAILY_GENERATION ? count : MAX_DAILY_GENERATION
        );
      } else {
        // Reset count if date has changed
        updateAvatarCount(MAX_DAILY_GENERATION);
      }
    } else {
      updateAvatarCount(MAX_DAILY_GENERATION);
    }
  }, []);

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? avatarImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === avatarImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Modal
      closeButton
      aria-labelledby="modal-title"
      open={isVisible}
      onClose={onClose}
      css={{ border: "1px solid $border" }}
    >
      <Modal.Header>
        <Text b id="modal-title" size={20}>
          Generate Avatar
        </Text>
      </Modal.Header>

      <Modal.Body>
        <Textarea
          style={{ resize: "vertical" }}
          autoFocus
          placeholder="Describe the image you want"
          bordered
          aria-labelledby="describe-image"
          minRows={5}
          maxRows={10}
          initialValue={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <Text
          align="end"
          color="$success"
          css={{ marginRight: "6%", color: avatarCount <= 1 ? "red" : "" }}
          size={12}
        >
          Available Today: {avatarCount}
        </Text>

        {avatarUrl && !isLoading && (
          <Image
            src={
              avatarUrl == currentAvatarUrl
                ? getAvatarUrl(avatarUrl)
                : avatarImages[currentImageIndex]
            }
            width={256}
            height={256}
          />
        )}
        <Grid>
          {avatarImages.length > 1 && !isLoading && (
            <Row>
              <Button
                disabled={currentImageIndex == 0}
                size="xs"
                auto
                onPress={handlePrevious}
                css={{ mr: "10px" }}
              >
                Previous
              </Button>

              {currentImageIndex !== avatarImages.length - 1 && (
                <Button size="xs" auto onPress={handleNext}>
                  Next
                </Button>
              )}
            </Row>
          )}
        </Grid>

        {isLoading && (
          <Container css={{ w: 256, h: 256 }} justify="center" align="center">
            <Loading color="currentColor" />
          </Container>
        )}
        {error}
      </Modal.Body>
      <Modal.Footer>
        {isLoading && (
          <Button disabled auto bordered color="primary" css={{ px: "$13" }}>
            <Loading color="currentColor" size="sm" />
          </Button>
        )}
        {!isLoading && (
          <Button
            auto
            onPress={onGenerate}
            disabled={prompt === "" || avatarCount < 1}
          >
            Generate
          </Button>
        )}
        <Button auto flat onPress={handleSave} disable={isError}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AvatarModal.propTypes = {
  currentAvatarUrl: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default AvatarModal;
