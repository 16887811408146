import { useEffect } from "react";
import { Loading, Spacer } from "@nextui-org/react";
import PropTypes from "prop-types";
import CharacterForm from "@/components/CharacterForm/CharacterForm";
import useApi from "@/components/useApi";
import MetaTags from "@/components/common/MetaTags";

export default function EditCharacterPage({ characterId, closeHandler }) {
  const {
    data,
    jsonApiService,
    callApi,
    isLoading,
    isLoading: isSubmitting,
    error: submitError,
  } = useApi();

  useEffect(() => {
    async function fetchData() {
      await callApi(
        jsonApiService.fetchResource,
        "admin/characters",
        characterId
      );
    }
    fetchData();
  }, [characterId, callApi, jsonApiService.fetchResource]);

  const onSave = (props) => {
    return jsonApiService.updateResource(
      "admin/characters",
      characterId,
      props
    );
  };

  if (isLoading) return <Loading />;
  if (!data) return null;

  return (
    <>
      <MetaTags index="EditCharacterPage" properties={{ character: data }} />
      <Spacer />
      <CharacterForm
        {...data}
        tags={data?.tags?.map((tag) => {
          return { label: tag, value: tag };
        })}
        onSave={onSave}
        onCancel={() => closeHandler()}
        isSubmitting={isSubmitting}
        submitError={submitError}
      />
    </>
  );
}
EditCharacterPage.propTypes = {
  characterId: PropTypes.string,
  closeHandler: PropTypes.func,
};
