import { PropTypes } from "prop-types";
import { Spacer, Text,Row } from "@nextui-org/react";
import { useState } from "react";
import { useField } from "@formiz/core";

const FormField = (props) => {
  let { component: Component, title, description } = props;
  const { value, setValue,errorMessage, isValid, isPristine,isSubmitted, otherProps } = useField(props);
  const [isFocused, setIsFocused] = useState(false);
  const showError = !isValid && !isFocused && (!isPristine || isSubmitted)
  const {rightText, ...filteredProps} = otherProps
  return (
    <>
      <Text b css={{ ml: "2px" }}>
        {title}
      </Text>
      <Text css={{ ml: "12px" }} small>
        {description}
      </Text>
      <Spacer y={0.1} />      
      <Component
        {...filteredProps}
        initialValue={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => setValue(e?.target?.value)}
        helperText={showError?errorMessage:''}
        helperColor={"error"}
  />
      {rightText&&<Row justify='flex-end'><Text size={14}>{rightText}</Text></Row>}
      <Spacer y={1} />
    </>
  );
};

FormField.propTypes = {
  component: PropTypes.elementType.isRequired,
  textRight: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  rightText: PropTypes.string,
};
export default FormField;
